import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {BreakpointService} from '../utils/breakpoint.service'
import {PartnerSection} from '../../core/content/types/partner-section.types'
import {AsyncPipe, NgTemplateOutlet} from '@angular/common'
import {I18nDirective} from '../i18n/i18n.directive'
import {SliderModule} from '../slider/slider.module'
import {CardsModule} from '../cards/cards.module'
import {RouterLink} from '@angular/router'
import {ContentSectionComponent} from '../content-section/content-section.component'

@Component({
  selector: 'cft-partners',
  templateUrl: './partners.component.html',
  styles: ':host { display: block; }',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Quick fix for hydration issue. Story created to get rid of the breakpoint service here in the future.
  host: {ngSkipHydration: 'true'}, // eslint-disable-line @angular-eslint/no-host-metadata-property
  imports: [ContentSectionComponent, AsyncPipe, I18nDirective, SliderModule, NgTemplateOutlet, CardsModule, RouterLink],
  standalone: true,
})
export class PartnersComponent {
  @Input() section!: PartnerSection

  constructor(readonly breakpointService: BreakpointService) {}
}
