import {ServiceSectionFieldsFragment, ServicesItemFieldsFragment} from '../../../../__generated__/datocms.types'
import {ChipColour, ServicesItem, ServicesSection} from '../types/services-section.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseCallToAction} from './call-to-action.serde'
import {parseFontAwesomeIcon} from './icon.serde'

export function parseServicesSection(s: ServiceSectionFieldsFragment | undefined): ServicesSection | undefined {
  if (!s) {
    return undefined
  }
  return {
    __type: 'ServicesSection',
    id: s.id,
    title: s.title!,
    plot: s.plot,
    description: s.description,
    items: parseServicesItemCollection(s.items),
  }
}

function parseServicesItemCollection(items: ServicesItemFieldsFragment[]): ServicesItem[] {
  return items.map(item => parseServicesItem(item as ServicesItemFieldsFragment)) ?? []
}

function parseServicesItem(e: ServicesItemFieldsFragment): ServicesItem {
  const entry = validateDatoCmsTypes(e)

  return {
    id: entry.id,
    title: entry.title,
    callToAction: parseCallToAction(entry.callToAction),
    icon: entry.serviceIcon ? parseFontAwesomeIcon(entry.serviceIcon) : undefined,
    chip:
      entry.chipText && entry.chipColour
        ? {text: entry.chipText, colour: parseChipColour(entry.chipColour)}
        : undefined,
    shortDescription: entry.shortDescription,
  }
}

function parseChipColour(colour: string): ChipColour {
  switch (colour) {
    case 'Red':
      return 'red'
    case 'Blue':
      return 'blue'
    default:
      return 'red'
  }
}
