<ng-container *cftI18n="let t">
  @if (breakpointService.breakpoints$ | async; as breakpoints) {
    <cft-content-section
      [sectionId]="section.id"
      [plot]="section.plot"
      [title]="section.title"
      [titleAlignment]="'left'"
    >
      @if (breakpoints.md) {
        <div class="grid gap-sm md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          <ng-container *ngTemplateOutlet="partners"></ng-container>
        </div>
      }
      @if (!breakpoints.md) {
        <cft-slider
          [alignCenter]="false"
          [backButtonTitle]="t('slider.back')"
          [forwardButtonTitle]="t('slider.forward')"
        >
          <ng-container *ngTemplateOutlet="partners"></ng-container>
        </cft-slider>
      }
      <ng-template #partners>
        @for (item of section.items; track item; let last = $last) {
          <cft-image-card
            class="cursor-pointer"
            [title]="item.title"
            [description]="item.description"
            [background]="item.logo"
            [routerLink]="item.targetPath"
            [class.pr-sm]="last"
          >
          </cft-image-card>
        }
      </ng-template>
    </cft-content-section>
  }
</ng-container>
